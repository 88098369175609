@import url('https://fonts.googleapis.com/css2?family=Afacad+Flux:wght@100..1000&display=swap');
.about {
    min-height: 1000px;  /* Set to viewport height to make it more flexible */
    width: 100%;
    background-color: #F0EBE1;
    display: flex;
    padding-top: 100px;
    overflow-x: hidden;
    align-items: flex-start;
    justify-content: space-between;
    box-sizing: border-box;
   
}
  

.title {
    font-size: 24px;  /* Set a base font size */
}
  
.logo{
    padding-left: 20px;
    padding-top:15px;
}

.btn{
    border: 1px solid #191919;
    background-color: transparent;
    padding: 10px 30px 10px 30px;
    /* top right bottom left */
    border-radius: 22px;
    font-family: "Afacad Flux", sans-serif;
    font-size: 21px;
    margin-right: 50px;
    margin-left: 0px;
    margin-top: 60px;
    color: black;
    cursor: pointer;
}


.btn:hover{
    background-color: rgba(240, 248, 255, 0.627);
}

@media (max-width: 767px) {
    .about{
        flex-direction: column;
        padding-top: 100px;
        align-items: center;
        justify-content: flex-start;
        min-height: 850px;
    }

    .title{
        font-size: 18px;  /* Make font smaller on mobile */
        width: 90%;
        padding-left: 20px;
        padding-right: 20px;
    }

    .btn{
        margin-right: 0px;
        color: black;
    }
}

@media(min-width: 768px) and (max-width: 1024px) {
    .about{
        padding-top: 120px;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
    .title{
        width: 50%;
        font-size: 22px;
    }
    .btn{
        margin-top: 10px;
    }

    .logo{
        padding: 0;
    }
}




