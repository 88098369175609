@import url('https://fonts.googleapis.com/css2?family=Tenor+Sans&display=swap');

/* General Card Styles (Desktop Default) */
.card {
    font-family: "Tenor Sans", sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* height: 100%; */
    height: auto;
    width: 450px; /* Default width for desktop */
    transition: box-shadow 0.3s ease;
}

.card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Image Styling (Desktop Default) */
.cardImage {
    width: 450px;
    height: 250px;
    object-fit: cover;
}

/* Title and Text Styles (Desktop Default) */
.cardTitle {
    margin-bottom: 12px;
    color: #050D18;
    font-family: "Tenor Sans", sans-serif;
    font-weight: bold;
    font-size: 19.5px;
    text-align: center;
    padding-right: 50px;
    padding-left: 50px;
}

.cardText {
    color: #050D18;
    font-family: "Tenor Sans", sans-serif;
    font-size: 15px;
    text-align: center;
    padding-right: 50px;
    padding-left: 50px;
}

/* Media Queries */

/* Mobile Styles */
@media (max-width: 767px) {
    .card {
        width: 100%; /* Full width on mobile */
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .cardImage {
        width: 100%; /* Full width */
        height: 150px; /* Smaller height for mobile */
    }

    .cardTitle {
        font-size: 19.5px;
        padding-right: 10px;
        padding-left: 10px;
    }

    .cardText {
        font-size: 15px;
        padding-right: 10px;
        padding-left: 10px;
    }
}

/* iPad Mini Styles (768px - 819px) */
@media (min-width: 768px) and (max-width: 819px) {
    .card {
        width: 300px;
    }

    .cardImage {
        width: 290px;
        height: 140px;
    }

    .cardTitle {
        padding-right: 10px;
        padding-left: 10px;
    }

    .cardText {
        padding-right: 10px;
        padding-left: 10px;
    }
}

/* iPad Air and Pro Styles (820px - 1024px) */
@media (min-width: 820px) and (max-width: 1024px) {
    .card {
        width: 400px;
    }

    .cardImage {
        width: 400px;
        height: 170px;
    }

    .cardTitle {
        padding-right: 10px;
        padding-left: 10px;
    }

    .cardText {
        padding-right: 10px;
        padding-left: 10px;
    }
}
