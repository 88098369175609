@import url('https://fonts.googleapis.com/css2?family=Tenor+Sans&display=swap');

/* General styles for the project section */
.partThreeProject {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 700px;
    display: flex;
    align-items: center;
    color: #fff;
    position: relative; /* For positioning the overlay and inner content */
}

/* Content section */
.contentSection {
    padding-left: 20px;
    z-index: 2;
    display: grid;
    height: 100%;
    grid-template-columns: 40% 60%;
    grid-template-rows: 100%;
    width: 100%;
}

/* Text group inside the content section */
.textGroup {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row: 1;
    height: 100%;
    font-family: "Tenor Sans", sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* Image group inside the content section */
.imageGroup {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row: 1;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: baseline;
}

/* Project title styling */
.projectTitle {
    font-size: 45px;
    margin-bottom: 10px;
    font-family: "Tenor Sans", sans-serif;
}

/* Link section styling */
.linkSection {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-size: 18px;
    font-family: "Tenor Sans", sans-serif;
}

.linkSection i {
    margin-right: 10px;
}

.linkSection a:hover {
    text-decoration: underline;
}

/* Overlay for the project background */
.partThreeProject::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Dark overlay */
    z-index: 1;
}

/* Image styling */
.slideImage {
    max-width: 250px;
    height: auto;
    margin-left: 10px;
    margin-right: 10px;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    border-radius: 8px;
    will-change: opacity, transform;
    z-index: 2;
}

.slideImage:first-child {
    margin-bottom: auto;
    margin-top: 25px;
}

.slideImage:last-child {
    margin-top: auto;
    margin-bottom: 25px;
}

/* Summary text */
.summaryText {
    font-family: "Tenor Sans", sans-serif;
}

/* Media Queries */

/* Mobile Styles (max-width: 767px) */
@media (max-width: 767px) {
    .partThreeProject {
        height: 790px;
    }

    .contentSection {
        grid-template-columns: 100%;
        grid-template-rows: 40% 60%;
    }

    .textGroup {
        grid-column: 1;
        grid-row-start: 1;
        grid-row-end: 2;
    }

    .imageGroup {
        grid-column: 1;
        grid-row-start: 2;
        grid-row-end: 3;
    }

    .projectTitle {
        font-size: 25px;
        padding-top: 20px;
    }

    .linkSection {
        font-size: 15px;
    }

    .slideImage {
        width: 130px;
    }

    .summaryText {
        font-size: 15px;
        padding-right: 7px;
    }
}

/* iPad Mini and iPad Air Styles (768px - 820px) */
@media (min-width: 768px) and (max-width: 820px) {
    .slideImage {
        width: 200px;
    }
}

/* iPad Pro and Larger Tablets Styles (821px - 1120px) */
@media (min-width: 821px) and (max-width: 1120px) {
    .slideImage {
        width: 200px;
    }
}
