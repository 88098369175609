@import url('https://fonts.googleapis.com/css2?family=Tenor+Sans&display=swap');


.form{
    font-family: "Tenor Sans", sans-serif;
    background-color: #F0EBE1;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-evenly;
    gap:10px;
    text-align: center;
    align-items: center;
    position: relative;
    padding-bottom: 3%;

}

.form h4{
    text-align: center;
    font-size: 60px;
    padding:50px;
    margin:0;
    @media (max-width:767px){
        font-size: 45px;
    }
}

.input{
    background: white;
    border: 0 none;
    border-radius: 5px;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
    padding: 20px 30px;
    box-sizing: border-box;
    width: 80%;
    margin: 0 10%;
    position: relative;
    font-family: "Tenor Sans", sans-serif;

}

.textArea{
    background: white;
    font-family: "Tenor Sans", sans-serif;
    border: 0 none;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
    padding: 20px 30px;
    box-sizing: border-box;
    width: 80%;
    margin: 0 10%;
    position: relative;
    border-radius: 5px;

}

.btn{
    font-family: "Tenor Sans", sans-serif;
    width: 100px;
    background: #050D18;
    font-weight: bold;
    color: white;
    border: 0 none;
    border-radius: 25px;
    cursor: pointer;
    padding: 10px 30px 10px 30px;
    margin: 10px 5px;
    
}

.btn:hover{
    box-shadow: 0 0 0 2px white, 0 0 0 3px #050D18;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #050d182b;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    justify-content: center;
    align-items: center;
}

.popupContent {
    background-color: #F0EBE1;
    padding: 30px 30px 70px;
    border-radius: 5px;
    display: flex;
    height: 400px;
    width: 350px;
    text-align: center;
    font-family: "Tenor Sans", sans-serif;
    color: black;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    justify-content: center;
    align-items: center;

    @media (max-width:767px){
        width: 270px;
    }

}


.popupContent h3{
    font-size: 20px;
    @media (max-width:767px){
        width: 100%;
    }
}
.popupContent p{
    margin-bottom: 40px;
    font-size: 15px;
    @media (max-width:767px){
        width: 100%;
    }
}
.popupLogo{
    padding-top: 10px;
    width: 300px;
    height: auto;
    padding-bottom: 50px;
    @media (max-width:767px){
        width: 100%;
    }

}

.popupClose{
    background-color: #ed6755;
    border: none;
    border-radius: 5px;
    width: 250px;
    padding: 14px;
    font-size: 15px;
    color: white;
    box-shadow: 0px 6px 18px -5px rgba(237, 103, 85, 1);
    font-family: "Tenor Sans", sans-serif;
    cursor: pointer;
    
}


