body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; /* Keep this for horizontal control */
  overflow-y: auto;   /* Allow vertical scrolling */
  scroll-behavior: smooth; /* Keep for smooth scrolling */
}

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

/* Mobile-specific adjustments */
@media (max-width: 767px) {
  body {
      overflow-y: auto; /* Ensure vertical scrolling is allowed */
  }
}
