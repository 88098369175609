@import url('https://fonts.googleapis.com/css2?family=Tenor+Sans&display=swap');

/* General container styles */
.container {
    margin-top: -280px;
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    min-height: auto;
    gap: 0;
    align-content: center;
}

/* Part One Layout */
.partOne {
    background-color: #050D18;
    display: grid;
    grid-template-columns: 30% 70%;
    height: 550px;
    width: 100%;
    gap: 200px;
}

/* Part One Left */
.partOneLeft {
    grid-column-start: 1;
    grid-column-end: 2;
}

.imageOne {
    background-image: url('/public/images/Dark\ Blue\ and\ Beige\ Simple\ Dark\ Consultancy\ Portfolio\ &\ Resume\ Website-5.png');
    background-repeat: no-repeat;
    background-position: calc(100px);
    min-height: 100%;
}

/* Part One Right */
.partOneRight {
    grid-column-start: 2;
    grid-column-end: 3;
}

/* Title styling */
.title {
    color: white;
    font-family: "Tenor Sans", sans-serif;
    font-weight: 400;
    font-style: normal;
    padding-top: 50px;
    font-size: 61px;
    margin-bottom: 10px;
}

/* Text styling */
.text {
    color: #F0EBE1;
    font-family: "Tenor Sans", sans-serif;
    font-weight: 400;
    font-style: normal;
    padding-right: 360px;
    font-size: 20px;
}

/* Part Two */
.partTwo {
    background-color: #F0EBE1;
    height: 650px;
    width: 100%;
    font-family: "Tenor Sans", sans-serif;
    display: grid;
    grid-template-rows: 20% 80%;
    row-gap: 25px;
    padding-top: 25px;
    grid-template-columns: 100%;
}

/* Part Two Headline */
.partTwoHeadline {
    grid-row-start: 1;
    grid-row-end: 2;
    background-image: url('/public/images/Dark\ Blue\ and\ Beige\ Simple\ Dark\ Consultancy\ Portfolio\ &\ Resume\ Website-2.svg');
    background-repeat: no-repeat;
    background-position: center;
}

/* Part Two Services */
.partTwoServices {
    padding-right: 20px;
    padding-left: 20px;
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column: 1;
    display: flex;
    justify-content: space-evenly;
    /* Scrollbar styles
    ::-webkit-scrollbar {
        width: 5px;
    }
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        background: #B23E3E;
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    } */
}

/* Part Three */
.partThree {
    background-color: #050D18;
    min-height: 1750px;
    width: 100%;
}

/* Part Three Headline */
.partThreeheadline {
    background-image: url('/public/images/imagecircle3-2.svg');
    background-repeat: no-repeat;
    background-position: left;
    width: 100%;
    min-height: 250px;
}

/* Media Queries */

/* Mobile View */
@media (max-width: 767px) {
    .partOne {
        grid-template-rows: 30% 70%;
        grid-template-columns: 100%;
        row-gap: 5px;
        min-height: 650px;
    }

    .partOneLeft {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column: 1;
    }

    .partOneRight {
        grid-row-start: 2;
        grid-row-end: 3;
        grid-column: 1;
        align-items: center;
    }

    .imageOne {
        padding-top: 10px;
        background-size: contain;
        background-position: left;
        margin-left: 20px;
        margin-top: 10px;
    }

    .title {
        font-size: 24px;
        text-align: center;
    }

    .text {
        font-size: 14.8px;
        text-align: center;
        padding: 10px;
        padding-right: 40px;
        padding-left: 40px;
    }
    .partTwo{
        row-gap: 0px;
        grid-template-rows: 10% 20%;
        height: 1500px;
        grid-template-columns: 100%;
    }
    .partTwoHeadline {
        background-image: url('/public/images/about\ \(Your\ Story\).svg');
        background-size: contain;
        max-height: 150px;
        width: 100%;
    }

    .partThree {
        min-height: 800px;
    }

    .partThreeheadline {
        background-size: contain;
        height: 150px;
        width: 80%;
        margin-left: 10px;
    }

    .partTwoServices {
        flex-wrap: wrap;
        gap: 30%;
    }
}

/* iPad Mini + iPad Air */
@media (min-width: 768px) and (max-width: 820px) {
    .partOne {
        grid-template-columns: 35% 65%;
        gap: 10px;
        padding: 5px;
    }

    .imageOne {
        background-position: left;
    }

    .title {
        padding-right: 15px;
        font-size: 45px;
        padding-left: 40px;
    }

    .text {
        padding-right: 30px;
        font-size: 17.5px;
        padding-left: 40px;
    }

    .partTwo {
        row-gap: 1px;
    }
}

/* iPad Air and Pro */
@media (min-width: 821px) and (max-width: 1120px) {
    .partOne {
        grid-template-columns: 40% 60%;
    }

    .text {
        padding-right: 30px;
        padding-left: 40px;
    }
}
