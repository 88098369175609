@import url('https://fonts.googleapis.com/css2?family=Tenor+Sans&display=swap');

html {
    margin: 0;
    padding: 0;
}

.nav {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: #050D18;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    font-family: "Tenor Sans", sans-serif;
    font-weight: 400;
}

li::marker {
    color: transparent;
}

.nav ul li a:hover {
    color: #555;
}
.nav .ul li a,
.nav .ul li a:after,
.nav .ul li a:before {
  transition: all .5s;
}
/* Desktop Menu */
.ul {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    margin: 0;
    list-style: none;
    width: 100%;
}

a {
    color: white;
    text-decoration: none;
    font-size: 15px;
}

.nav .ul li a{
  position: relative;
  letter-spacing: 5px;

}
.nav .ul li a:after{
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  width: 0%;
  content: '';
  color: transparent;
  background: #aaa;
  color: #aaa;
  height: 1px;
}
.nav .ul li a:hover:after {
  width: 100% !important;
  opacity: 1;
}

.nav .ul li a {
  transition: all 2s;
  position: relative;
  color: white;
  text-decoration: none;
  display: inline-block;
}

.nav .ul li a:after {
  text-align: left;
  content: '.';
  margin: 0;
  opacity: 0;
}




/* Mobile Styles */
@media (max-width: 768px) {
    .navTrigger {
        display: block;  /* Show hamburger icon on mobile */
        z-index: 1001;
        cursor: pointer;
    }

    .navIcon {
        width: 30px;
        height: 2px;
        background-color: white;
        position: relative;
        display: inline-block;
        transition: background-color 0.4s ease;
    }

    .navIcon:before,
    .navIcon:after {
        content: '';
        width: 30px;
        height: 2px;
        background-color: white;
        position: absolute;
        left: 0;
        transition: transform 0.4s ease, top 0.4s ease;
    }

    .navIcon:before {
        top: -8px;
    }

    .navIcon:after {
        top: 8px;
    }

    /* Active hamburger icon - turns into an "X" */
    .navIconActive {
        background-color: transparent;
    }

    .navIconActive:before {
        top: 0;
        transform: rotate(45deg);
    }

    .navIconActive:after {
        top: 0;
        transform: rotate(-45deg);
    }

    .ul {
        position: fixed;
        top: 0;
        left: 100%;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.9); /* Full screen dark background */
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 40px;
        transition: left 0.4s ease-in-out, opacity 0.4s ease-in-out;
        pointer-events: none;
        opacity: 0; /* Menu starts hidden */
    }

    .ul.open {
        left: 0; /* Slide in */
        opacity: 1; /* Make the menu visible */
        pointer-events: auto; /* Enable clicking */
    }

    .ul.closed {
        left: 100%; /* Slide out */
        opacity: 0; /* Make the background color invisible */
        pointer-events: none;
    }

    .navLink {
        font-size: 24px;
        color: whitesmoke;
        text-decoration: none;
        margin: 20px 0;
        transition: opacity 0.4s ease-in-out 0.2s; /* Fade in */
    }

    .navLink:hover {
        text-decoration: underline;
    }
}
