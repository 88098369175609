@import url('https://fonts.googleapis.com/css2?family=Tenor+Sans&display=swap');

/* General Footer Styles (Desktop Default) */
.footer {
    background-color: #050D18;
    color: #F0EBE1;
    padding: 40px 0;
    font-family: 'Tenor Sans', sans-serif;
    align-items: center;
}

.footerGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    max-width: 1200px;
    margin: 0 auto;
    padding: 0px 20px;
    gap: 30px;
    justify-content: center; 
    align-content: center; 
    justify-items: center;
}

.footerColumn {
    display: flex;
    flex-direction: column;
    align-self: center;
}

.footerHeading {
    font-size: 18px;
    margin-bottom: 15px;
}

.footerLink {
    color: #F0EBE1;
    text-decoration: none;
    margin-bottom: 10px;
    font-size: 16px;
}

.footerLink:hover {
    text-decoration: underline;
}

.footerCopy {
    text-align: center;
    margin-top: 20px;
    padding-top: 40px;
    font-size: 14px;
}

/* Media Queries */

/* Mobile Styles (max-width: 480px) */
@media (max-width: 480px) {
    .footerGrid {
        grid-template-columns: 1fr;
        padding: 0 10px;
    }

    .footerColumn {
        align-items: center;
    }

    .footerHeading {
        font-size: 12px;
    }

    .footerLink {
        font-size: 12px;
    }

    .footerCopy {
        font-size: 12px;
    }
}

/* Tablet Styles (max-width: 768px) */
@media (max-width: 768px) {
    .footerGrid {
        grid-template-columns: repeat(2, 1fr);
    }

    .footerHeading {
        font-size: 15px;
    }

    .footerLink {
        font-size: 13px;
    }

    .footerCopy {
        font-size: 13px;
    }
}
